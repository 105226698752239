export default function NutralFaceIcon(props: {
  width?: number;
  height?: number;
  color?: string;
  rotate?: number;
}) {
  return (
    <svg
      width={props.width ?? "134"}
      height={props.height ?? "134"}
      viewBox="0 0 134 134"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_47_233)">
        <path
          d="M67 134C84.7695 134 101.811 126.941 114.376 114.376C126.941 101.811 134 84.7695 134 67C134 49.2305 126.941 32.1888 114.376 19.6238C101.811 7.05891 84.7695 0 67 0C49.2305 0 32.1888 7.05891 19.6238 19.6238C7.05891 32.1888 0 49.2305 0 67C0 84.7695 7.05891 101.811 19.6238 114.376C32.1888 126.941 49.2305 134 67 134ZM58.625 54.4375C58.625 61.372 54.873 67 50.25 67C45.627 67 41.875 61.372 41.875 54.4375C41.875 47.503 45.627 41.875 50.25 41.875C54.873 41.875 58.625 47.503 58.625 54.4375ZM33.5 87.9375C33.5 86.8269 33.9412 85.7618 34.7265 84.9765C35.5118 84.1912 36.5769 83.75 37.6875 83.75H96.3125C97.4231 83.75 98.4882 84.1912 99.2735 84.9765C100.059 85.7618 100.5 86.8269 100.5 87.9375C100.5 89.0481 100.059 90.1132 99.2735 90.8985C98.4882 91.6838 97.4231 92.125 96.3125 92.125H37.6875C36.5769 92.125 35.5118 91.6838 34.7265 90.8985C33.9412 90.1132 33.5 89.0481 33.5 87.9375ZM83.75 67C79.127 67 75.375 61.372 75.375 54.4375C75.375 47.503 79.127 41.875 83.75 41.875C88.373 41.875 92.125 47.503 92.125 54.4375C92.125 61.372 88.373 67 83.75 67Z"
          fill="#6FC3F9"
        />
      </g>
      <defs>
        <clipPath id="clip0_47_233">
          <rect width="134" height="134" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
