export default function CloseIcon(props: {
  width?: number;
  height?: number;
  onClick?: () => void;
  style?: React.CSSProperties;
  color?: string;
}) {
  return (
    <div style={props.style}>
      <svg
        onClick={props.onClick}
        width={props.width ?? "20"}
        height={props.height ?? "20"}
        viewBox="0 0 671 671"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19.2002 19.2002C31.202 7.20209 47.4777 0.461914 64.4482 0.461914C81.4187 0.461914 97.6945 7.20209 109.696 19.2002L336 245.504L562.304 19.2002C574.375 7.54212 590.541 1.09129 607.322 1.23711C624.102 1.38293 640.155 8.11374 652.021 19.9799C663.887 31.846 670.618 47.898 670.763 64.6786C670.909 81.4592 664.458 97.6257 652.8 109.696L426.496 336L652.8 562.304C664.458 574.375 670.909 590.541 670.763 607.322C670.618 624.102 663.887 640.155 652.021 652.021C640.155 663.887 624.102 670.618 607.322 670.763C590.541 670.909 574.375 664.458 562.304 652.8L336 426.496L109.696 652.8C97.6257 664.458 81.4592 670.909 64.6786 670.763C47.898 670.618 31.846 663.887 19.9799 652.021C8.11374 640.155 1.38293 624.102 1.23711 607.322C1.09129 590.541 7.54212 574.375 19.2002 562.304L245.504 336L19.2002 109.696C7.20209 97.6945 0.461914 81.4187 0.461914 64.4482C0.461914 47.4777 7.20209 31.202 19.2002 19.2002V19.2002Z"
          fill={props.color ?? "white"}
        />
      </svg>
    </div>
  );
}
