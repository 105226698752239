export default function SmileFaceIcon(props: {
  width?: number;
  height?: number;
  color?: string;
  rotate?: number;
}) {
  return (
    <svg
      width={props.width ?? "136"}
      height={props.height ?? "136"}
      viewBox="0 0 136 136"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_47_231)">
        <path
          d="M68 136C86.0347 136 103.331 128.836 116.083 116.083C128.836 103.331 136 86.0347 136 68C136 49.9653 128.836 32.6692 116.083 19.9167C103.331 7.16427 86.0347 0 68 0C49.9653 0 32.6692 7.16427 19.9167 19.9167C7.16427 32.6692 0 49.9653 0 68C0 86.0347 7.16427 103.331 19.9167 116.083C32.6692 128.836 49.9653 136 68 136V136ZM59.5 55.25C59.5 59.5085 58.106 58.616 55.9725 57.2475C54.57 56.3465 52.853 55.25 51 55.25C49.147 55.25 47.43 56.355 46.0275 57.2475C43.894 58.616 42.5 59.5 42.5 55.25C42.5 48.212 46.308 42.5 51 42.5C55.692 42.5 59.5 48.212 59.5 55.25ZM104.814 80.75C105.56 82.0422 105.952 83.5079 105.952 85C105.952 86.4921 105.56 87.9578 104.814 89.25C101.084 95.7131 95.7181 101.08 89.2555 104.81C82.793 108.541 75.4619 110.503 68 110.5C60.5395 110.502 53.2102 108.538 46.7493 104.808C40.2884 101.078 34.9237 95.7118 31.195 89.25C30.4493 87.9585 30.0566 86.4935 30.0562 85.0022C30.0558 83.5109 30.4478 82.0457 31.1928 80.7538C31.9378 79.4619 33.0096 78.3887 34.3006 77.642C35.5915 76.8954 37.0562 76.5015 38.5475 76.5H97.4525C98.9445 76.5 100.41 76.8928 101.702 77.6388C102.995 78.3849 104.067 79.4579 104.814 80.75ZM89.9725 57.2475C88.57 56.3465 86.853 55.25 85 55.25C83.147 55.25 81.43 56.355 80.0275 57.2475C77.894 58.616 76.5 59.5 76.5 55.25C76.5 48.212 80.308 42.5 85 42.5C89.692 42.5 93.5 48.212 93.5 55.25C93.5 59.5085 92.106 58.616 89.9725 57.2475V57.2475Z"
          fill="#5CD46C"
        />
      </g>
      <defs>
        <clipPath id="clip0_47_231">
          <rect width="136" height="136" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
