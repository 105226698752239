export default function QuestionMarkIcon(props: {
  height?: number;
  width?: number;
  onClick?: () => void;
  style?: React.CSSProperties;
}) {
  return (
    <svg
      style={props.style}
      onClick={props.onClick}
      width={props.width ?? "64"}
      height={props.height ?? "64"}
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30.1001 32.514C29.8365 32.5678 29.5997 32.711 29.4296 32.9193C29.2595 33.1277 29.1667 33.3884 29.1667 33.6573V36.1668C29.1667 36.631 28.9824 37.0761 28.6542 37.4043C28.326 37.7325 27.8809 37.9168 27.4167 37.9168C26.9526 37.9168 26.5075 37.7325 26.1793 37.4043C25.8511 37.0761 25.6667 36.631 25.6667 36.1668V31.1245C25.6668 30.6816 25.8348 30.2553 26.1368 29.9314C26.4389 29.6075 26.8525 29.4102 27.2942 29.3792C29.3686 29.2345 32.4194 28.5275 34.8951 26.949C37.3112 25.4067 39.0834 23.1165 39.0834 19.6527C39.0834 16.316 37.8456 14.0025 36.0477 12.4683C34.2067 10.8968 31.6447 10.0358 28.8937 9.9285C23.1992 9.70566 17.8921 12.6235 16.8747 17.1665C16.7734 17.6195 16.4963 18.0137 16.1043 18.2623C15.7123 18.511 15.2376 18.5937 14.7846 18.4924C14.3317 18.3911 13.9375 18.1139 13.6888 17.722C13.4402 17.33 13.3574 16.8553 13.4587 16.4023C14.2357 12.9327 16.4769 10.3928 19.3784 8.73033C22.2951 7.0585 25.8312 6.306 29.0314 6.432C32.3774 6.56266 35.7514 7.61383 38.3204 9.806C40.9337 12.0367 42.5834 15.3407 42.5834 19.6527C42.5834 24.597 39.9351 27.8847 36.7781 29.8995C34.6407 31.2633 32.2281 32.0812 30.1012 32.5128L30.1001 32.514ZM24.5001 46.6668C24.5001 45.7386 24.8688 44.8483 25.5252 44.192C26.1816 43.5356 27.0718 43.1668 28.0001 43.1668C28.9283 43.1668 29.8186 43.5356 30.4749 44.192C31.1313 44.8483 31.5001 45.7386 31.5001 46.6668C31.5001 47.5951 31.1313 48.4853 30.4749 49.1417C29.8186 49.7981 28.9283 50.1668 28.0001 50.1668C27.0718 50.1668 26.1816 49.7981 25.5252 49.1417C24.8688 48.4853 24.5001 47.5951 24.5001 46.6668Z"
        fill="#983838"
        stroke="#983838"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
